import { useDispatch } from "react-redux";
import { createNewsletter } from "reducers/newslettersReducer";
import SmallActionButton from "./SmallActionButton";

export default function NewBrewSmallButton() {
  const dispatch = useDispatch();

  return (
    <SmallActionButton
      noMobileTooltip={false}
      size={26}
      icon="plus"
      tooltip="Create new brew"
      onClick={() => dispatch(createNewsletter("New Brew"))}
    />
  );
}
