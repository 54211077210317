import { useConfig } from "@mailbrew/uikit";
import { useRouter } from "next/router";
import urls from "urls";
import plausible from "utils/plausible";
import { PillButton } from "./PillButton";

const ReadEditBrewsButton = ({ mode = "read" }) => {
  const config = useConfig();
  const isEdit = mode === "edit";
  const router = useRouter();

  function handleEditBrewsClick() {
    router.push(urls.manageBrews());
    plausible.track("Click on Edit Brews");
  }

  if (isEdit) {
    return (
      <PillButton icon="writeBold" onClick={handleEditBrewsClick}>
        Edit Brews
      </PillButton>
    );
  } else {
    return (
      <PillButton icon="writeBold" color={config.colors.accent1} onClick={() => router.push(urls.brews())}>
        Edit Brews
      </PillButton>
    );
  }
};

export default ReadEditBrewsButton;
