import { useEffect, useMemo, useState } from "react";
import timezones from "../data/timezones";

export default function useCurrentTimezone() {
  const timezoneOptions = useMemo(() => timezones.map((tz) => tz.name), []);

  const [currentTimezone, setCurrentTimezone] = useState(null);

  useEffect(() => {
    const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const inferredTimezone = timezoneOptions.find((tz) => tz === browserTimezone);
    if (inferredTimezone) setCurrentTimezone(inferredTimezone);
  }, [timezoneOptions]);

  return currentTimezone;
}
