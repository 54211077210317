import { notif, useTimeout } from "@mailbrew/uikit";
import api from "dependencies/api";
import { useCallback, useEffect, useRef, useState } from "react";
import useSWR from "swr";
import apiErrMsg from "utils/apiErrMsg";

const updateMessagesDelay = 6_000;
const generationTimeout = 90_000;

export default function useGenerateBrew(brewID, onGenerationCompeted) {
  const [isGenerating, setIsGenerating] = useState(false);
  const [issueID, setIssueID] = useState(null);
  const notifIdRef = useRef(null);

  const startGeneration = useCallback(async () => {
    try {
      const res = await api.post(`/newsletters/${brewID}/generate/`);
      setIssueID(res.data.issue_id);
      setIsGenerating(true);
      notifIdRef.current = notif.loading("Generating your brew...", { duration: 60_000 });
    } catch (err) {
      notif.error(apiErrMsg(err));
    }
  }, [brewID]);

  const { data: issue } = useSWR(issueID && `/issues/${issueID}/`, isGenerating && { refreshInterval: 1 });

  useEffect(() => {
    if (!issue) return;

    const success = ["published"].includes(issue.status);
    const failed = ["generation_failed", "generation_failed", "no_content"].includes(issue.status);

    if (isGenerating && success) {
      setIsGenerating(false);
      onGenerationCompeted(issue, notifIdRef.current);
    }

    if (isGenerating && failed) {
      setIsGenerating(false);

      const msg =
        issue.status === "no_content"
          ? issue.serial_number === 1
            ? "Your brew has no content, try adding some sources"
            : "No new content found since last issue"
          : `Generation failed (${issue.status})`;

      notif.error(msg, { id: notifIdRef.current, duration: 10_000 });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGenerating, issue]);

  // progress and timeouts

  useTimeout(
    () => {
      if (isGenerating) {
        notif.loading("Hang tight, this can take up to a minute...", { id: notifIdRef.current });
      }
    },
    updateMessagesDelay * 1,
    [isGenerating]
  );

  useTimeout(
    () => {
      if (isGenerating) {
        notif.loading("Are we there yet?", { id: notifIdRef.current });
      }
    },
    updateMessagesDelay * 3,
    [isGenerating]
  );

  useTimeout(
    () => {
      if (isGenerating) {
        notif.loading("Almost done...", { id: notifIdRef.current });
      }
    },
    updateMessagesDelay * 5,
    [isGenerating]
  );

  useTimeout(
    () => {
      if (isGenerating) {
        notif.error("Something went wrong", { id: notifIdRef.current, duration: 10_000 });
        setIsGenerating(false);
      }
    },
    generationTimeout,
    [isGenerating]
  );

  return { startGeneration, isGenerating };
}
