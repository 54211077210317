import { Icon, IconBadge, Switch, useConfig } from "@mailbrew/uikit";
import { useRouter } from "next/router";
import { useDispatch } from "react-redux";
import { mutate } from "swr";
import { updateNewsletterPaused } from "../reducers/newslettersReducer";
import urls from "../urls";
import StyledTooltip from "./StyledTooltip";

const BrewStatus = (props) => {
  const { brew } = props;
  const { schedule, sources, paused } = brew;

  const status = (() => {
    if (!schedule || !sources || sources.length === 0) return "setup";
    if (paused) return "paused";
    return "active";
  })();

  const config = useConfig();
  const dispatch = useDispatch();
  const router = useRouter();

  const states = {
    setup: {
      copy: "Setting up",
      color: config.colors.error,
      icon: "writeBold",
      tooltip: "Finish setting up this brew.",
      onClick: () => {
        router.push(urls.editNewsletter(brew.id));
      },
    },
    paused: {
      copy: "Paused",
      color: config.colors.c2,
      icon: "pauseBold",
      tooltip: "You are not receiving this brew.",
      onClick: async () => {
        mutate(
          "/newsletters/",
          (brews) => {
            return brews.map((b) => {
              if (b.id === brew.id) {
                return { ...b, paused: false };
              } else {
                return b;
              }
            });
          },
          false
        );
        await dispatch(updateNewsletterPaused(brew.id, false));
      },
    },
    active: {
      copy: "Active",
      color: config.colors.success,
      icon: "playBold",
      tooltip: "You are receiving this brew.",
      onClick: async () => {
        mutate(
          "/newsletters/",
          (brews) => {
            return brews.map((b) => {
              if (b.id === brew.id) {
                return { ...b, paused: true };
              } else {
                return b;
              }
            });
          },
          false
        );
        await dispatch(updateNewsletterPaused(brew.id, true));
      },
    },
  };

  const { icon, color, tooltip, onClick } = states[status];

  if (status === "paused" || status === "active") {
    return (
      <StyledTooltip title={tooltip}>
        <Switch small on={status === "active"} onClick={() => onClick && onClick()}>
          {status !== "active" && <Icon name="pauseBold" color={config.colors.c5} size="10px" />}
        </Switch>
      </StyledTooltip>
    );
  }

  return (
    <StyledTooltip title={tooltip}>
      <IconBadge size="20px" name={icon} color={color} onClick={() => onClick && onClick()} />
    </StyledTooltip>
  );
};

export default BrewStatus;
