import {
  Box,
  Button,
  ButtonSelect,
  getColorFromCssVariable,
  Modal,
  MultiSegmentedControl,
  P1,
  P2,
  SmallText,
  Stack,
  useBreakpoint,
  useConfig,
} from "@mailbrew/uikit";
import api from "dependencies/api";
import useOnValueChange from "hooks/useOnValueChange";
import { Fragment, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import tinycolor from "tinycolor2";
import { useImmer } from "use-immer";
import { capitalize } from "utils/string";
import { keyNewsletterById } from "utils/swrKeys";
import { currentNewsletterSelector, updateNewsletterField } from "../reducers/newslettersReducer";
import {
  allWeekdaysDoubleLetter,
  allWeekdaysLong,
  allWeekdaysNumbers,
  makeDailySchedule,
  makeMonthlySchedule,
  makeTime,
  makeWeeklySchedule,
  migrateSchedule,
  MO,
  weekdaysNumbersJustWeekend,
  weekdaysNumbersNoWeekend,
} from "../utils/schedules";
import DayOfMonthSelector from "./DayOfMonthSelector";
import TimeSelector from "./TimeSelector";

export const ScheduleEditor = ({ compact, newsletter: providedNewsletter, style }) => {
  const config = useConfig();
  const dispatch = useDispatch();

  const _newsletter = useSelector(currentNewsletterSelector);
  const newsletter = providedNewsletter ?? _newsletter;

  const migratedSchedule = useMemo(() => migrateSchedule(newsletter.schedule), [newsletter.schedule]);

  const [selectedScheduleType, setSelectedScheduleType] = useState(migratedSchedule.type);
  const [localState, updateLocalState] = useImmer({
    ...makeDefaultSchedules(),
    [migratedSchedule.type]: migratedSchedule,
  });

  const currentSchedule = localState[selectedScheduleType];

  useOnValueChange(currentSchedule, () => {
    handleSave();
  });

  const handleChangeScheduleType = (type) => {
    // remember times when changing schedule type
    updateLocalState((state) => {
      state[type].times = state[selectedScheduleType].times;
    });

    setSelectedScheduleType(type);
  };

  const handleUpdateTimes = (times) => {
    updateLocalState((state) => {
      state[selectedScheduleType].times = times;
    });
  };

  const handleUpdateWeekdays = (weekdays) => {
    updateLocalState((state) => {
      state[selectedScheduleType].weekdays = weekdays.slice().sort();
    });
  };

  const handleUpdateDayOfMonth = (value) => {
    updateLocalState((state) => {
      state[selectedScheduleType].day_of_month = value;
    });
  };

  const handleSave = () => {
    const newSchedule = localState[selectedScheduleType];
    if (providedNewsletter) {
      // update brew with direct api call
      api.patch(keyNewsletterById(providedNewsletter.id), { schedule: newSchedule });
    } else {
      // update brew in redux, middleware will take care of sync (works in the editor)
      dispatch(updateNewsletterField("schedule", newSchedule));
    }
  };

  return (
    <Box style={style}>
      <P2 color={config.colors.c1}>
        {!compact && "Send "}
        <StyledButtonSelect
          options={["daily", "weekly", "monthly"]}
          formatOption={(_, i) => ["Daily", "Weekly", "Monthly"][i]}
          selectedOption={selectedScheduleType}
          onChange={handleChangeScheduleType}
        >
          {capitalize(selectedScheduleType)}
        </StyledButtonSelect>{" "}
        {selectedScheduleType === "monthly" && (
          <Fragment>
            {" "}
            on the{" "}
            <DayOfMonthSelector
              value={localState[selectedScheduleType].day_of_month}
              onChange={handleUpdateDayOfMonth}
            />{" "}
            day{" "}
          </Fragment>
        )}
        {selectedScheduleType === "weekly" && (
          <Fragment>
            {" "}
            on{" "}
            <Weekdays
              localState={localState}
              selectedScheduleType={selectedScheduleType}
              handleUpdateWeekdays={handleUpdateWeekdays}
            />{" "}
          </Fragment>
        )}
        at <TimeSelector times={localState[selectedScheduleType].times} setTimes={handleUpdateTimes} />
      </P2>
      {!compact && newsletter.subscribers_count > 0 && (
        <SmallText mt={1.5}>Subscribers get this digest at the same time as you.</SmallText>
      )}
    </Box>
  );
};

const Weekdays = ({ localState, selectedScheduleType, handleUpdateWeekdays }) => {
  const hit = useBreakpoint(480);
  const [weekdaysModalShown, setWeekdaysModalShown] = useState(false);

  const weekdays = localState[selectedScheduleType].weekdays;

  if (!weekdays) return null;

  return (
    <Fragment>
      <InlineButton onClick={() => setWeekdaysModalShown(true)}>
        {(() => {
          if (weekdays.join("") === weekdaysNumbersNoWeekend.join("")) return "weekdays";
          if (weekdays.join("") === weekdaysNumbersJustWeekend.join("")) return "weekends";
          return weekdays.map((weekdayIndex, index) => {
            const last = index === weekdays.length - 1;
            if (weekdays.length < 2) return capitalize(allWeekdaysLong[weekdayIndex]);

            return allWeekdaysDoubleLetter[weekdayIndex] + (last ? "" : ", ");
          });
        })()}
      </InlineButton>
      <Modal bottomSheet={hit} width="420px" show={weekdaysModalShown} setShow={setWeekdaysModalShown}>
        <P1 mb={2.5} maxW="92%">
          Which days of the week you want to receive&nbsp;it?
        </P1>
        <MultiSegmentedControl
          options={allWeekdaysNumbers}
          optionsNames={allWeekdaysDoubleLetter}
          active={weekdays}
          onOptionChange={handleUpdateWeekdays}
          allowEmptySelection={false}
          buttonProps={{
            variant: ["small"],
            style: {
              zoom: hit ? 0.85 : 1,
            },
          }}
          w="100%"
        />
        <Stack align={hit ? "stretch" : "right"} mt={3}>
          <Button
            icon="checkmark"
            variant="secondary"
            w={hit ? "100%" : "100px"}
            onClick={() => setWeekdaysModalShown(false)}
          >
            Okay
          </Button>
        </Stack>
      </Modal>
    </Fragment>
  );
};

// const TimePicker =>

export const InlineButton = (props) => {
  const config = useConfig();
  const borderColor = tinycolor(getColorFromCssVariable(config.colors.accent1)).setAlpha(0.4).toString();
  return (
    <Button
      variant="link"
      fontSize="100%"
      style={{ position: "relative", borderBottom: `1px solid ${borderColor}` }}
      {...props}
    />
  );
};

export const StyledButtonSelect = (props) => {
  const config = useConfig();
  const borderColor = tinycolor(getColorFromCssVariable(config.colors.accent1)).setAlpha(0.4).toString();
  return (
    <ButtonSelect
      motionEffects={null}
      variant="link"
      fontSize="100%"
      style={{ borderBottom: `1px solid ${borderColor}`, display: "inline-block", paddingBottom: "0px" }}
      {...props}
    />
  );
};

function makeDefaultSchedules() {
  return {
    daily: makeDailySchedule([makeTime(9, 0)]),
    weekly: makeWeeklySchedule([MO], [makeTime(9, 0)]),
    monthly: makeMonthlySchedule(15, [makeTime(9, 0)]),
  };
}
