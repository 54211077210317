/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { HStack, Icon, P1, SubtleCard, useBreakpoint, useConfig } from "@mailbrew/uikit";
import { motion } from "framer-motion";
import { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { createNewsletter } from "reducers/newslettersReducer";

const EmptyBrewCard = (props) => {
  const config = useConfig();
  const dispatch = useDispatch();

  const breakpointHit = useBreakpoint();

  const [hovering, setHovering] = useState(false);

  const isHovering = breakpointHit ? true : hovering;

  return (
    <Fragment>
      <SubtleCard
        width="100%"
        inline
        flex
        ai="center"
        jc="center"
        minH="14em"
        style={{ cursor: "pointer" }}
        onClick={() => dispatch(createNewsletter("New Brew"))}
        background={isHovering ? config.colors.c2 : config.colors.c4}
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
      >
        <HStack as={motion.div} align="center">
          <Icon
            as={motion.svg}
            layout
            transition={{ type: "spring", stiffness: 100, mass: 0.4 }}
            name="plus"
            size="20px"
            strokeWidth="1.5px"
            color={isHovering ? config.colors.c3 : config.colors.c4}
          />
          {isHovering && (
            <P1
              key="add-brew"
              as={motion.div}
              initial={{ opacity: 0, x: 30 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 30 }}
              transition={{ type: "spring", stiffness: 100, mass: 0.4 }}
              color={isHovering ? config.colors.c3 : config.colors.c4}
            >
              New Brew
            </P1>
          )}
        </HStack>
      </SubtleCard>
    </Fragment>
  );
};

export default EmptyBrewCard;
