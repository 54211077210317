import {
  Box,
  Button,
  Card,
  CardFooter,
  Divider,
  H3,
  notif,
  P1,
  Stack,
  useBreakpoint,
  useConfig,
  VStack,
} from "@mailbrew/uikit";
import useGenerateBrew from "hooks/useGenerateBrew";
import Link from "next/link";
import { useRouter } from "next/router";
import { Fragment } from "react";
import confirmPromise from "utils/confirmPromise";
import { pluralize } from "utils/pluralize";
import urls from "../urls";
import BrewManageOptionsDropdown from "./BrewManageOptionsDropdown";
import BrewStatus from "./BrewStatus";
import PublishBrewButton from "./PublishBrewButton";
import { ScheduleEditor } from "./ScheduleEditor";
import SourcesBadges from "./SourcesBadges";
import StyledLink from "./StyledLink";
import UnstyledA from "./UnstyledA";

const BrewCard = (props) => {
  const {
    brew,
    brew: { title, untitled, sources },
  } = props;

  const config = useConfig();
  const isMobile = useBreakpoint();
  const router = useRouter();

  const hasIssues = brew.published_issues_count > 0;

  const { startGeneration, isGenerating } = useGenerateBrew(brew.id, (issue, notifID) => {
    notif.success("Enjoy your brew 🙌", { duration: 5000, id: notifID });
    router.push(urls.brews() + "?navigateToLatestIssue=true", urls.brews());
  });

  async function handleGenerateBrew() {
    if (brew.subscribers_count > 0) {
      const confirmed = await confirmPromise(
        `${brew.subscribers_count} ${pluralize("subscriber", brew.subscribers_count)} will get this brew. Are you sure?`
      );
      if (confirmed) {
        startGeneration();
      }
    } else {
      startGeneration();
    }
  }

  function handleEditClick() {
    router.push(urls.editNewsletter(brew.id));
  }

  return (
    <Fragment>
      <Card width="100%" inline flex fd="column" overflow="hidden">
        <Box w="100%" gap={0} style={{ flex: "1 0 auto" }}>
          <Stack align="spaced" vAlign="top" gap={2} noWrap overflow="hidden" mb={1}>
            <VStack gap={1.5} style={{ alignSelf: "stretch" }} overflow="hidden" maxW="100%">
              <Link href={urls.viewNewsletter(brew.share_url)}>
                <UnstyledA>
                  <H3
                    color={!untitled ? config.colors.c1 : config.colors.c5}
                    style={{ cursor: "pointer", overflow: "hidden", maxWidth: "100%" }}
                    noWrap
                    overflow="hidden"
                    maxW="100%"
                  >
                    {title}
                  </H3>
                </UnstyledA>
              </Link>
              <div style={{ flex: "1 1 auto" }} />
            </VStack>
            <BrewStatus brew={brew} />
          </Stack>
          {sources?.length > 0 ? (
            <SourcesBadges sources={sources} size={28} />
          ) : (
            <P1 noWrap color={config.colors.c4}>
              No sources yet...
            </P1>
          )}
        </Box>
        <Divider mt={3} mb={3} />
        <Box>
          <ScheduleEditor compact newsletter={brew} />
        </Box>

        <CardFooter inline style={{ flex: 0 }}>
          <Stack align="spaced" noWrap>
            <BrewManageOptionsDropdown
              newsletter={brew}
              forceIsSelf
              handleGenerateNewIssue={hasIssues && !isGenerating && handleGenerateBrew}
            />
            <Stack w="100%" noWrap align="right">
              {!hasIssues && (
                <Button glow={!isGenerating} icon="refresh" softLoading={isGenerating} onClick={handleGenerateBrew}>
                  {isMobile ? "Generate" : "Generate first issue"}
                </Button>
              )}
              {hasIssues && (
                <div>
                  <PublishBrewButton newsletter={brew} />
                </div>
              )}
              {hasIssues && (
                <StyledLink icon="text" variant="white" to={urls.viewNewsletter(brew.share_url)} stretch>
                  Read
                </StyledLink>
              )}

              <Button variant="white" icon="writeBold" onClick={handleEditClick}>
                Edit
              </Button>
            </Stack>
          </Stack>
        </CardFooter>
      </Card>
    </Fragment>
  );
};

export default BrewCard;
