import { Box, Button, HStack, Section, Stack } from "@mailbrew/uikit";
import EmptyBrewCard from "components/EmptyBrewCard";
import EmptyStateCard from "components/EmptyStateCard";
import NewBrewSmallButton from "components/NewBrewSmallButton";
import ReadEditBrewsButton from "components/ReadEditBrewsButton";
import TimezoneDetector from "components/TimezoneDetector";
import { motion } from "framer-motion";
import loggedInPage from "hoc/loggedInPage";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useSWR from "swr";
import BrewCard from "../components/BrewCard";
import BrewsGrid from "../components/BrewsGrid";
import Page from "../components/Page";
import { authUserSelector } from "../reducers/authReducer";
import { createNewsletter, setCurrentNewsletter } from "../reducers/newslettersReducer";

const ManageBrewsPage = () => {
  const dispatch = useDispatch();

  const { data: brews = [], isValidating } = useSWR("/newsletters/");
  const user = useSelector(authUserSelector);

  useEffect(() => {
    dispatch(setCurrentNewsletter(null));
  }, [dispatch]);

  const isEmptyState = !isValidating && brews.length === 0;

  return (
    <Page
      loading={isValidating}
      showMonetizationBanner
      showTimedOffer
      title="Manage Brews"
      leftHeaderComponents={
        <Stack noWrap>
          <ReadEditBrewsButton mode="read" />
          <NewBrewSmallButton />
        </Stack>
      }
    >
      {isEmptyState && (
        <Section center>
          <BrewEmptyStateCard />
        </Section>
      )}
      {!isValidating && brews.length > 0 && (
        <Section>
          <TimezoneDetector />
          <Box as={motion.div}>
            <BrewsGrid>
              {brews.map((brew) => (
                <BrewCard key={brew.id} user={user} brew={brew} />
              ))}
              <EmptyBrewCard />
            </BrewsGrid>
          </Box>
        </Section>
      )}
    </Page>
  );
};

export const BrewEmptyStateCard = () => {
  const dispatch = useDispatch();
  const user = useSelector(authUserSelector);
  return (
    <EmptyStateCard
      showLogo
      title={`${user.first_name}, create your first brew`}
      description={`Create a digest from your favorite topics and people.`}
    >
      <HStack align="center" noWrap>
        <Button glow icon="plus" onClick={() => dispatch(createNewsletter("New Brew"))}>
          Create Brew
        </Button>
      </HStack>
    </EmptyStateCard>
  );
};

export default loggedInPage(ManageBrewsPage);
